<template>
  <div>
    <cadastro-base
      :salvar="editarOuSalvar"
      :cancelar="cancelar"
      :is-editar="isEditar"
    >
      <template slot="conteudo">
        <b-row>
          <b-form-group
            label="Razão Social"
            class="col-md-6"
          >
            <b-form-input
              v-model="form.razaoSocial"
              :disabled="true"
            />
          </b-form-group>

          <b-col cols="4">
            <b-form-group label="CNPJ/CPF">
              <masked-input
                v-model="form.cadastroFederal"
                placeholder="CNPJ ou CPF para cadastro"
                class="form-control"
                :mask="mascaraCadastroFederal"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col cols="6">
            <b-form-group label-for="PlanosEmpresariais">
              <template #label>
                <span
                  class="d-flex align-items-bottom"
                  style="gap: 0.5rem"
                >
                  <span style="font-size: 1rem;">Plano contratado</span>
                  <feather-icon
                    v-b-tooltip.hover
                    :title="getToolTipPlano()"
                    icon="InfoIcon"
                    size="16"
                    class="text-warning"
                  />
                </span>
              </template>

              <validation-provider
                #default="{ errors }"
                name="Plano empresarial"
                rules="numberRequired"
              >
                <b-form-select
                  v-model="form.planoId"
                  :options="planosPadrao"
                  :disabled="!isEditar"
                  style="color: #6e6b7b;"
                  autofocus
                />
                <small
                  v-if="isEditar"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="ml-0 pl-0">
            <historico-geral
              :empresa="form.razaoSocial"
              :tipo-historico="1"
              :buscar-historico="getHistorico"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="7">
            <b-row align-v="center">
              <b-col cols="5">
                <b-form-group>
                  <template #label>
                    <span
                      class="d-flex align-items-bottom"
                      style="gap: 0.5rem"
                    >
                      <span style="font-size: 1rem;">Quantidade de CNPJs Contratados</span>
                      <feather-icon
                        v-b-tooltip.hover
                        title="Neste campo será exibido a quantidade de CNPJs contratados e/ou incluídos no sistema"
                        icon="InfoIcon"
                        size="16"
                        class="text-warning"
                      />
                    </span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Quantidade de cadastros"
                    rules="numberRequired"
                  >
                    <b-form-input
                      v-model="form.qtdCadastros"
                      :disabled="!isEditar"
                      class="form-control"
                      type="number"
                    />
                    <small
                      v-if="isEditar"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="auto"
                class="ml-0 p-0"
                style="margin-right: 0.55rem;"
              >
                <historico-geral
                  :empresa="form.razaoSocial"
                  :tipo-historico="2"
                  :buscar-historico="getHistorico"
                />
              </b-col>

              <b-col cols="5">
                <b-form-group>
                  <template #label>
                    <span
                      class="d-flex align-items-bottom"
                      style="gap: 0.5rem"
                    >
                      <span style="font-size: 1rem;">Quantidade de usuários com acesso a base</span>
                      <feather-icon
                        v-b-tooltip.hover
                        title="Quantidade de usuários para cadastro na base de dados"
                        icon="InfoIcon"
                        size="16"
                        class="text-warning"
                      />
                    </span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Quantidade de usuarios"
                    rules="numberRequired"
                  >
                    <b-form-input
                      v-model="form.qtdUsuarios"
                      :disabled="!isEditar"
                      class="form-control"
                      type="number"
                    />
                    <small
                      v-if="isEditar"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="1"
                class="ml-0 pl-0"
              >
                <historico-geral
                  :empresa="form.razaoSocial"
                  :tipo-historico="3"
                  :buscar-historico="getHistorico"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col cols="6">
            <b-form-group>
              <template #label>
                <span
                  class="d-flex align-items-bottom"
                  style="gap: 0.5rem"
                >
                  <span style="font-size: 1rem;">E-mail contato financeiro</span>
                  <feather-icon
                    v-b-tooltip.hover
                    title="E-mail que irá receber as atualizações sobre upgrade de novos planos"
                    icon="InfoIcon"
                    size="16"
                    class="text-warning"
                  />
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="form.emailFinanceiro"
                  :disabled="!isEditar"
                />
                <small
                  v-if="isEditar"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="1"
            class="ml-0 pl-0"
          >
            <historico-geral
              :empresa="form.razaoSocial"
              :tipo-historico="4"
              :buscar-historico="getHistorico"
            />
          </b-col>
        </b-row>
        <loading
          :show-cancel-button="false"
          :is-loading="isLoading"
          titulo-carregamento="Aguarde!"
          descricao="Salvando alterações"
        />
      </template>
    </cadastro-base>
  </div>
</template>

<script>
import { cpfDocumento, cnpjDocumento } from '@pilar/plugins/p-validation/p-validation'
import { ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import CadastroBase from '@pilar/components/cadastro-base/CadastroBase.vue'
import api from './shared/services/api'

export default {
  name: 'Plano',
  components: {
    CadastroBase,
    ValidationProvider,
    HistoricoGeral: () => import('./shared/components/historico-geral/HistoricoGeral.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  data() {
    return {
      cpfDocumento,
      cnpjDocumento,
      required,
      semPlano: { value: null, text: 'Selecione o plano empresarial', quantidade: null },
      planosPadrao: [],
      form: {
        id: 0,
        cadastroFederal: '',
        razaoSocial: '',
        qtdCadastros: 0,
        qtdUsuarios: 0,
        emailFinanceiro: '',
        planoId: null,
        rotinas: null,
      },
      isEditar: false,
      validacao: '',
      isLoading: false,
    }
  },
  created() {
    this.planosPadrao = [this.semPlano]
    this.carregar()
  },
  methods: {
    carregar() {
      api.get()
        .then(payload => {
          this.form = payload.data
          this.planosPadrao = [this.semPlano, ...this.form.planosPadrao]
        })
        .catch(err => {
          throw err
        })
    },
    editarOuSalvar() {
      if (!this.isEditar) {
        this.isEditar = true
      } else {
        this.isLoading = true
        return new Promise((resolve, reject) => {
          api.sendPlano(this.form)
            .then(() => {
              resolve('Plano alterado com sucesso')
              this.isEditar = false
            })
            .catch(err => {
              reject(err)
              this.$erro(err.message)
            })
            .finally(() => {
              this.isLoading = false
            })
        })
      }
      return ''
    },
    cancelar() {
      this.$router.push({ name: 'dashboard-analitico' })
    },
    isCnpj() {
      return this.form.cadastroFederal
        .replace('___', '')
        .replace('__', '')
        .replace('_', '')
        .replace('-', '')
        .replace('/', '')
        .replace('.', '').length > 11
    },
    mascaraCadastroFederal() {
      if (this.isCnpj()) {
        this.validacao = 'cnpjDocumento'
        return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
      }

      this.validacao = 'cpfDocumento'
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    },
    getToolTipPlano() {
      return `Este é o plano contratado para sua empresa. Ele abrange as seguintes rotinas: ${this.form.rotinas}. O plano compreende a atualização uma vez por mês de todas as suas rotinas (exceto situações especificas de CNDs), e uma atualização manual de sua escolha. Caso necessário você poderá adquirir novos créditos através do menu "Aquisição e créditos`
    },
    getHistorico(tipoHistorico) {
      return api.getHistorico(tipoHistorico)
    },
  },
}
</script>
