import axios from '@axios'

const PREFIX = '/loja'

export default {

  get() {
    return axios.get(`${PREFIX}/planos`)
  },

  sendPlano(form) {
    return axios.post(`${PREFIX}/planos`, form)
  },

  getHistorico(tipoHistorico) {
    return axios.get(`${PREFIX}/historico?tipo=${tipoHistorico}`)
  },
}
